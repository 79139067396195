export const AdvomateLogo = () => <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g style={{
    mixBlendMode: "multiply"
  }}>
      <path d="M21.9727 50C21.9727 34.521 34.521 21.9727 50 21.9727H78.0273V50C78.0273 65.479 65.479 78.0273 50 78.0273C34.521 78.0273 21.9727 65.479 21.9727 50Z" fill="#DC5F75" />
    </g>
    <g style={{
    mixBlendMode: "multiply"
  }}>
      <path d="M50 21.9727C65.479 21.9727 78.0273 34.521 78.0273 50V78.0273C62.5483 78.0273 50 65.479 50 50V21.9727Z" fill="#E3AC59" />
      <path fillRule="evenodd" clipRule="evenodd" d="M64.0137 74.2777C72.391 69.4317 78.0273 60.374 78.0273 50C78.0273 34.521 65.479 21.9727 50 21.9727V50C50 60.374 55.6363 69.4317 64.0137 74.2777Z" fill="#E3AC59" />
    </g>
  </svg>;