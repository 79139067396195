import { NextPage } from "next";
import { Auth } from "../components/layouts/Auth";
import { SignIn } from "../components/templates/SingIn";
const SignInPage: NextPage = () => {
  const title = "Vítejte zpět";
  const description = "Přihlašte se zpátky do svého účtu u Advomate";
  return <Auth heading={title} subheading={description}>
      <SignIn title={title} description={description} />
    </Auth>;
};
export default SignInPage;