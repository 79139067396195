import Head from "next/head";
import { SignInForm } from "../modules/forms/SignInForm";
interface SignInProps {
  title: string;
  description: string;
}
export const SignIn = ({
  title,
  description
}: SignInProps) => {
  return <>
      <Head>
        <title>Advomate | {title}</title>
        <meta name="description" content={`${description}.`}></meta>
      </Head>
      <SignInForm />
    </>;
};