import { useToast } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useState } from "react";

import { TOAST_DURATION } from "../../config/constants";
import {
  usePerformPasswordResetMutation,
  useRequestPasswordResetMutation,
  useSignInMutation,
  useSignUpMutation,
} from "../graphql";
import { ForgotPasswordInputs, ResetPasswordInputs, SignInInputs, SignUpInputs } from "../types/forms";

export const useAuth = () => {
  const [signUpMutation] = useSignUpMutation();
  const [signInMutation] = useSignInMutation();
  const [forgotPasswordMutation] = useRequestPasswordResetMutation();
  const [performPasswordResetMutation] = usePerformPasswordResetMutation();

  const [loadingSignUp, setLoadingSignUp] = useState<boolean>(false);
  const [loadingSignIn, setLoadingSignIn] = useState<boolean>(false);
  const [loadingForgotPassword, setLoadingForgotPassword] = useState<boolean>(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState<boolean>(false);

  const toast = useToast();
  const router = useRouter();

  const signUp = async (values: SignUpInputs): Promise<void> => {
    setLoadingSignUp(true);
    try {
      await signUpMutation({
        variables: {
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          password: values.password,
        },
      });

      router.push("/aktivace-uctu");
    } catch (error: any) {
      if (error.graphQLErrors[0].extensions?.code === "FORBIDDEN") {
        toast({
          title: "E-mail byl vyhodnocen jako spam",
          duration: TOAST_DURATION,
          status: "error",
          position: "bottom-right",
          variant: "subtle",
        });
      } else {
        toast({
          title: "Účet s tímto e-mailem již existuje",
          duration: TOAST_DURATION,
          status: "error",
          position: "bottom-right",
          variant: "subtle",
        });
      }
    } finally {
      setLoadingSignUp(false);
    }
  };

  const signIn = async (values: SignInInputs): Promise<void> => {
    setLoadingSignIn(true);
    try {
      const result = await signInMutation({
        variables: {
          email: values.email,
          password: values.password,
        },
      });
      const token = result.data?.sign_in.token;

      window.localStorage.setItem("rememberMe", values.rememberMe.toString());
      window.localStorage.setItem("jwt", token as string);

      router.push("/reserse");
    } catch (error: any) {
      if (error.graphQLErrors[0].extensions?.code === "FORBIDDEN") {
        toast({
          title: "Účet zatím nebyl aktivován",
          description: "Zkontrolujte si Váš e-mail pro aktivační link.",
          duration: TOAST_DURATION,
          status: "error",
          position: "bottom-right",
          variant: "subtle",
        });
      } else {
        toast({
          title: "Neplatný email nebo heslo",
          duration: TOAST_DURATION,
          status: "error",
          position: "bottom-right",
          variant: "subtle",
        });
      }
    } finally {
      setLoadingSignIn(false);
    }
  };

  const forgotPassword = async (values: ForgotPasswordInputs): Promise<void> => {
    setLoadingForgotPassword(true);
    try {
      await forgotPasswordMutation({
        variables: {
          email: values.email,
        },
      });

      router.push("/instrukce-zaslany");
    } catch {
      toast({
        title: "Žádný účet s tímto e-mailem neexistuje",
        duration: TOAST_DURATION,
        status: "error",
        position: "bottom-right",
        variant: "subtle",
      });
    } finally {
      setLoadingForgotPassword(false);
    }
  };

  const resetPassword = async (id: string, values: ResetPasswordInputs): Promise<void> => {
    setLoadingResetPassword(true);
    try {
      const result = await performPasswordResetMutation({
        variables: {
          request_id: id,
          new_password: values.password,
        },
      });
      const token = result.data?.perform_password_reset.token;

      window.localStorage.setItem("jwt", token as string);

      router.push("/reserse");
    } catch {
      toast({
        title: "Link je neplatný nebo expiroval",
        duration: TOAST_DURATION,
        status: "error",
        position: "bottom-right",
        variant: "subtle",
      });
    } finally {
      setLoadingResetPassword(false);
    }
  };

  return {
    signUp,
    signIn,
    forgotPassword,
    resetPassword,
    loadingSignUp,
    loadingSignIn,
    loadingForgotPassword,
    loadingResetPassword,
  };
};
