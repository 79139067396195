import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import jsonwebtoken from "jsonwebtoken";
import { useRouter } from "next/router";
import NextNprogress from "nextjs-progressbar";
import { ReactNode, useEffect, useState } from "react";
import { AdvomateLogo } from "../elements/svgs/AdvomateLogo";
interface AuthProps {
  heading: string;
  subheading: string;
  children: ReactNode;
}
export const Auth = ({
  heading,
  subheading,
  children
}: AuthProps) => {
  const [loaded, setLoaded] = useState<boolean>(false);
  const router = useRouter();
  useEffect(() => {
    if (window === undefined || !router.isReady) return;
    const jwt = window.localStorage.getItem("jwt");
    const rememberMe = window.localStorage.getItem("rememberMe");
    const decoded = jwt === null ? null : jsonwebtoken.decode(jwt);
    if (decoded === null || typeof decoded === "string" || !("userId" in decoded) || decoded.exp === undefined || decoded.exp < Date.now() / 1000) {
      window.localStorage.clear();
    } else if (rememberMe === "true") {
      router.replace("/reserse");
    }
    setLoaded(true);
  }, [router]);
  if (!loaded) return null;
  return <>
      <NextNprogress color="#DD5F75" height={2} options={{
      showSpinner: false
    }} showOnShallow />
      <Flex align="center" direction="column" gap={6} h="100vh" bg="#FFFFFF">
        <Box mt={[5, 16]}>
          <AdvomateLogo />
        </Box>
        <Flex align="center" direction="column" w="full" mt={["-10px", "0"]}>
          <Flex direction="column" maxW="400px" px={4}>
            <Heading mb={2} fontSize={["3xl", "4xl"]} textAlign="center">
              {heading}
            </Heading>
            <Text mb={8} px={5} color="gray.500" fontWeight="medium" textAlign="center">
              {subheading}
            </Text>
            {children}
          </Flex>
        </Flex>
      </Flex>
    </>;
};