import { Button, Checkbox, Flex, FormControl, Icon, Input, InputGroup, InputRightElement, Text } from "@chakra-ui/react";
import Link from "next/link";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useAuth } from "../../../hooks/useAuth";
import { SignInInputs } from "../../../types/forms";
export const SignInForm = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const {
    signIn,
    loadingSignIn
  } = useAuth();
  const {
    register,
    handleSubmit,
    formState: {
      errors
    }
  } = useForm<SignInInputs>({
    mode: "onSubmit",
    defaultValues: {
      rememberMe: false
    }
  });
  const onSubmit: SubmitHandler<SignInInputs> = data => {
    signIn(data);
  };
  return <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" w="full">
        <FormControl mb={3} isInvalid={errors.email !== undefined} isRequired>
          <Input focusBorderColor="lightBrand" size="lg" {...register("email", {
          required: true
        })} placeholder="E-mail" type="email" />
        </FormControl>
        <FormControl mb={2} isInvalid={errors.password !== undefined} isRequired>
          <InputGroup>
            <Input focusBorderColor="lightBrand" size="lg" {...register("password", {
            required: true
          })} autoComplete="current-password" placeholder="Heslo" type={showPassword ? "text" : "password"} />
            <InputRightElement h="46px" mr={3} cursor="pointer" onClick={() => setShowPassword(!showPassword)}>
              <Icon as={showPassword ? FiEyeOff : FiEye} w={4} h={4} />
            </InputRightElement>
          </InputGroup>
        </FormControl>
        <Flex justify="space-between" direction={["column", "row", "row", "row"]} mb={6}>
          <Checkbox {...register("rememberMe")} defaultChecked iconColor="white">
            Zapamatovat si mě
          </Checkbox>
          <Link href="/zapomenute-heslo">
            <Button mt={[2, 0, 0, 0]} variant="link">
              Zapomněli jste heslo?
            </Button>
          </Link>
        </Flex>
        <Button mb={3} isLoading={loadingSignIn} type="submit" variant="brand">
          Příhlásit se
        </Button>
        <Flex justifyItems="center" justify="center" my={3}>
          <Text>Nemáte ještě účet?&nbsp;</Text>
          <Text>
            <Link href="/tvorba-uctu">
              <Button variant="link">Tvorba účtu</Button>
            </Link>
          </Text>
        </Flex>
      </Flex>
    </form>;
};